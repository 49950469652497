@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #460edb;
  --secondary: #250874;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-sm;
}

h1 {
  color: var(--primary);
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

a {
  @apply block hover:underline pt-2 pb-4;
}

.bg-primary {
  background-color: var(--primary);
}

.color-primary {
  color: var(--primary);
}

.center {
  @apply flex items-center justify-center;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;

  @apply transition-all;
}

.nav-item:hover {
  background: #fff;
  color: var(--primary);
  text-decoration: none;
}

.nav-item:hover svg {
  fill: var(--primary);
}

.nav-item--active {
  background: #fff;
  color: var(--primary) !important;
  text-decoration: none;
}

.nav-item--active svg {
  fill: var(--primary);
}

.nav-layout-content {
  min-height: calc(100vh - 70px);
}

.sidebar-item {
  @apply py-4 px-4 rounded-md hover:text-white hover:no-underline hover:no-underline mt-1 transition-all;
}

.sidebar-item--active {
  background-color: var(--primary);
  color: #fff;
}

.sidebar-item--active svg {
  fill: #fff;
}

.sidebar-item:hover {
  background-color: var(--primary);
}

.sidebar-item:hover svg {
  fill: #fff;
}

.box {
  @apply w-auto bg-white shadow-md border border-solid border-gray-200 rounded-md;
}

.input {
  @apply w-full border border-solid border-gray-200 rounded-md p-3 mb-2 text-sm focus:outline-none focus:ring-4 focus:ring-purple-50 focus:border-purple-50;
}

input[type='color'].input {
  width: 52px;
  height: 52px;
  padding: 0;
  margin: 0;
  border: 0;
}

input[type='checkbox'].input {
  width: auto;
}

.input-label {
  @apply block w-full my-2 text-gray-700;
}

.input-text-error {
  @apply block text-sm text-red-500 mb-2;
}

.link {
  @apply block hover:underline;
}

.button {
  min-height: 44px;
  @apply block py-3 px-5 rounded-md w-full text-sm text-center flex items-center justify-center md:w-auto hover:no-underline;
}

.button:disabled {
  background-color: rgba(194, 194, 194, 0.637);
  @apply cursor-not-allowed;
}

.button--small {
  min-height: auto !important;
  line-height: 0.5rem !important;
  @apply p-2 text-xs;
}

.button--full {
  @apply w-full md:w-full;
}

.button--large {
  @apply py-4 px-12 text-lg;
}

.button--light {
  @apply font-normal border border-gray-200 text-gray-400;
}

.button--primary {
  background-color: var(--primary);
  @apply text-white border-0;
}

.table-container {
  @apply w-full border rounded-md my-2 p-4 overflow-hidden;
}

.table {
  @apply w-full;
}

.table thead tr th {
  @apply text-left px-0 py-4 text-lg;
}

.table tbody tr td {
  @apply py-4 border-b border-gray-100;
}

.board-section {
  min-width: 272px;
}
